// extracted by mini-css-extract-plugin
export var mobileAppCardTechStack = "dq_BC";
export var mobileAppCardTechStack__card = "dq_BD";
export var mobileAppCardTechStack__description = "dq_BG";
export var mobileAppCardTechStack__iconsMobileDev = "dq_BH";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechJS = "dq_BM";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechKotlin = "dq_BK";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechReact = "dq_BL";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechSwift = "dq_BJ";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechTS = "dq_BN";
export var mobileAppCardTechStack__title = "dq_BF";