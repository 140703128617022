// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "z_d2";
export var devBenefitBussinesMobileDev = "z_dZ";
export var domainExpertiseMobileDev = "z_dV";
export var mobileAppDevTechnologies = "z_dR";
export var mobileAppDevTechnologies__wrapper = "z_dS";
export var ourKeyPrinciplesMobileDev = "z_dY";
export var serviceMobileTestimonialPost = "z_dX";
export var servicesCardBorderIconMobileDevSection = "z_dP";
export var servicesCardWithTopBorderSectionMobileApp = "z_dQ";
export var servicesProcessMobileDevSection = "z_dW";
export var successStoriesMobileSection = "z_d1";
export var weBuildProjectsDifferentSection = "z_dT";
export var whyChooseServicesMobile = "z_d0";